import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './PricelistPage.module.scss';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import PriceCard from '../../components/mui/priceCard/PriceCard';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import { getRequest } from '../../services/fetch/AxiosHelper';

const PricelistPage = () => {
  const location = useLocation();
  const [priceList, setPriceList] = useState([]);

  const linksData = [
    { path: "/", label: "Home" },
    { path: "/pricelist", label: "Pricelist" }
  ];

  const getPriceTable = (price) => {

    const priceTable = [
      { quantity: '1 pc.', euro: price.pricePer1 / 100 },
      { quantity: '5 pc.', euro: price.pricePer5 / 100},
      { quantity: '10 pc.', euro: price.pricePer10 / 100},
      { quantity: '30 pc.', euro: price.pricePer30 / 100},
      { quantity: '100 pc.', euro: price.pricePer100 / 100},
      { quantity: '250 pc.', euro: price.pricePer250 / 100},
      { quantity: '500 pc.', euro: price.pricePer500 / 100},
      { quantity: '1000 pc.', euro: price.pricePer1000 / 100}
    ]

    const filteredPriceTable = priceTable.filter(item => item.euro !== undefined && !isNaN(item.euro)).slice(0, 5);

    return filteredPriceTable;
  };

  useEffect(() => {
    getRequest(`/model/`)
            .then((response) => {
              const responseData = response.data;
              setPriceList(responseData);
            })
            .catch((error) => console.error('Error fetching goods:', error));
  }, [location.search]);

  return (
    <Container className={classnames(styles.pageContainer)}>
        <Box my={'10px'} display='flex' alignItems={'center'} >
            <div className={classnames('mb-2')}>
                <DynamicBreadcrumbs links={linksData} />
            </div>
        </Box>

        <Typography component='h3' className={classnames(styles.paragraphHeader)}>
            Pricelist
        </Typography>
        <Typography component='p' className={classnames(styles.paragraph)}>
            Prices are current as of today. To find out what discounts and promotions we have available write to our manager, he will advise you in detail on our offers
        </Typography>
    
        <div className={classnames(styles.priceListContainer)}>
            {priceList.map((price, index) => (
                <PriceCard
                key={index}
                title={price.name}
                img={price.image}
                priceTable={getPriceTable(price)}
                characteristic={price.description}
                />
            ))}
        </div>
    </Container>
  );
};

export default PricelistPage;