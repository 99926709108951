import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './JobsPage.module.scss';
import classnames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';

const JobsPage = () => {
  const location = useLocation();
  const [isFetchOk, setIsFetchOk] = useState(false);

  useEffect(() => {
    getRequest(`/jobs/trigger/all`)
      .then((response) => {
        const responseData = response.data;
        setIsFetchOk(responseData);
      })
      .catch((error) => console.error('Error fetching google sheets table:', error));
  }, [location.search]);


  return (
    <Container className={classnames(styles.pageContainer)}>
      {isFetchOk ? "Data updated from google sheets": "Something went wrong, data was not updated"}
    </Container>
  );
};

export default JobsPage;