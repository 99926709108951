import React, { useEffect, useState } from 'react';
import { Drawer,  List, ListItem, AppBar, Toolbar, Typography, Box, FormControlLabel, Checkbox, Collapse, styled } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './Filter.module.scss';
import classnames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OrangeButton from '../orangeButton/OrangeButton';
import { getRequest } from '../../../services/fetch/AxiosHelper';
import { useLocation, useNavigate } from 'react-router-dom';


const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      borderRadius: 4,
      color: 'transparent',
      backgroundColor: '#F3F3F3',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
      border: '2px solid #EBA500',
      backgroundColor: 'transparent',
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      border: '2px solid #EBA500',
      outline: '6px solid #EBA500',
      outlineOffset: '-6px',
      color: '#EBA500',
    },

  }));

const Filter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [expandLiquids, setExpandLiquids] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);

  const getCategory = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("category");
  };

  useEffect(() => {
    getRequest(`/model/by-category?category=${getCategory()}`)
            .then((response) => {
              const responseData = response.data;
              setAvailableModels(responseData);
            })
            .catch((error) => console.error('Error fetching available models:', error));
  }, []);

  const handleFilterChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      [event.target.name]: event.target.checked,
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const toggleExpandLiquids = () => {
    setExpandLiquids(!expandLiquids);
  };

  const applyFilters = () => (event) => {
    const selectedModels = Object.keys(selectedFilters).filter(key => selectedFilters[key]);
    const modelsString = selectedModels.join(',');
    const queryString = new URLSearchParams({
      category: getCategory(),
      models: modelsString,
    }).toString();
    navigate(`/category?${queryString}`);
    setOpen(false);
  }

  const menuList = () => (
    <div
      role="presentation"
      className={classnames(styles.menuDrawer)}
    >
      <Box className={classnames(styles.filterCategory)} onClick={toggleExpandLiquids}>
        <Typography className={classnames(styles.filterCategoryTitle)}>{getCategory()}</Typography>
        {expandLiquids ? <ExpandLessIcon sx={{color: '#43361C'}}/> : <ExpandMoreIcon sx={{color: '#43361C'}}/>}
      </Box>
      <Collapse in={expandLiquids} timeout="auto" unmountOnExit>
        <Box className={classnames(styles.filterCategoryContent)}>
          <List>
            {availableModels.map((filter) => (
              <ListItem key={filter} className={classnames(styles.listItem)}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                        checked={selectedFilters[filter]}
                        onChange={handleFilterChange}
                        name={filter}
                    />
                  }
                  label={filter}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>
    </div>
  );

  return (
    <Box my={'10px'} display='flex' alignItems={'center'} >
      <AppBar position="static" sx={{backgroundColor: 'transparent', boxShadow: 'none'}}>
        <Toolbar className={classnames(styles.toolbar)}>
            <Box className={classnames(styles.filterButton)} onClick={toggleDrawer(true)} >
                <FilterListIcon sx={{color: '#43361C', width: '24px', height: '24px'}}/>
            </Box>
            <Typography color="#F3F3F3" component="p" fontSize='22px' marginLeft='5px' alignItems='center'>
                Filter
            </Typography>  
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: { width: '100%', maxWidth: '480px' } }}
        classes={{ paper: classnames(styles.fullscreenDrawer) }}
      >
        {menuList()}

        <Box display={'flex'} position={'static'} paddingBottom={'40px'} width={'100%'} justifyContent={'center'} className={classnames(styles.applyFilter)}>
            <OrangeButton title={"Apply filters"} onClick={applyFilters(false)}/>
        </Box>
        
      </Drawer>
    </Box>
  );
};

export default Filter;
