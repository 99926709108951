import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicComponent = ({children}) => {
 
const location = useLocation()

const [showNavBar, setShowNavBar] = useState(false)

useEffect(() => {

  const doesNotMatch = !new RegExp(`^\/auth\/password\/[^/]+$`).test(location.pathname);

  const isLogin = new RegExp(`^\/auth\/login$`).test(location.pathname);
  const isAdminPanel = new RegExp(`^\/admin`).test(location.pathname);

  setShowNavBar(!isLogin && doesNotMatch && !isAdminPanel);
}, [location]);


return (
    <div>
      {showNavBar && children}
    </div>
  );
};


export default DynamicComponent;