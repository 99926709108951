import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Avatar, Grid } from '@mui/material';
import MenuIcon from '../../assets/images/MenuIcon.svg';
import classnames from 'classnames';
import PuffOptLogo from '../../assets/images/PuffOptLogo.svg'
import styles from './Header.module.scss';
import OrangeButton from '../mui/orangeButton/OrangeButton';
import AccountCircleIcon from '../../assets/images/AccountAvatar.svg';
import ShoppingCartIcon from '../../assets/images/ShoppingCart.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getRequest } from '../../services/fetch/AxiosHelper';
import { updateCart, resetUpdateHeader } from '../../redux/actions';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Cookies } from "react-cookie-consent";
import { postCsrf } from '../../services/fetch/AxiosHelper';

const mapDispatchToProps = (dispatch) => ({
  updateCart: () => dispatch(updateCart()),
  resetHeader: () => dispatch(resetUpdateHeader()),
});

const Header = ({resetHeader}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const [userInfo, setUserInfo] = useState([])
  const [itemCount, setItemCount] = useState(0)
  const shouldUpdateHeader = useSelector(state => state.cart.shouldUpdateHeader);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    getAuthToken()
  }, [location.search]);

  const getAuthToken = () => {
    const searchParams = new URLSearchParams(location.search);
    let chatId = searchParams.get("chatId");
    
    if (chatId) {

      const authRequest = {
        chatId: chatId
      };

      postCsrf(`/v1/login`, JSON.stringify(authRequest))
        .then((response) => {
          const responseData = response.data;
          setUserData(responseData)
          setUserInfo(responseData)
        })
        .catch((error) => console.error('Error setting auth token:', error));

        navigate('/');
    }
  }

  const setUserData = (userData) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('auth_token', userData.token, { expires: 365 })
      Cookies.set('username', userData.username, { expires: 365 })
      Cookies.set('chatId', userData.chatId, { expires: 365 })
      Cookies.set('avatar', userData.avatar, { expires: 365 })
    } else {
      Cookies.set('auth_token', userData.token)
      Cookies.set('username', userData.username)
      Cookies.set('chatId', userData.chatId)
      Cookies.set('avatar', userData.avatar)
    }
  }


  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'Liquids', path: '/category?category=4' },
    { name: 'Vape', path: '/category?category=1' },
    { name: 'Snus', path: '/category?category=2' },
    { name: 'Technics', path: '/category?category=3' },
  ];

  const getCartItemCount = () => {
    getRequest("cart/count")
        .then((response) => response.data)
        .then((data) => {
          setItemCount(data)
        })
        .catch((error) => {
          console.log(error)
        });
  }

  useEffect(() => {
    if (shouldUpdateHeader) {
      getCartItemCount()
      resetHeader()
    }
  }, [shouldUpdateHeader, resetHeader]);

  useEffect(() => {
    getCartItemCount()
  }, [])

  const menuList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={classnames(styles.menuDrawer)}
    >
      <Typography paddingX={"16px"} fontSize={"16px"} color={"gray"} paddingTop={"25px"} paragraph>
        Select the desired product   
      </Typography>
      <List>
        {menuItems.map((menuItem, index) => (
          <Link key={index} to={`${menuItem.path}`} style={{textDecoration: 'none'}}>
            <ListItem key={index} className={classnames({[styles.selectedMenuItem]: location.pathname === menuItem.path})}>
                <ListItemText to={'/'} sx={{color: '#F3F3F3'}} primary={menuItem.name} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Typography paddingX={"16px"} fontSize={"14px"} color={"gray"} paddingBottom={"25px"} paragraph>
        @PUFFOPT 2020-2024 
      </Typography>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" className={classnames(styles.header)}>
        <div className={classnames(styles.runningText)}>
          <Typography component="span" className={classnames(styles.runningTextContent)}>
            ELFBAR WHOLESALE · VOZOL WHOLESALE · HQD WHOLESALE · SNUS WHOLESALE     
          </Typography>
          <Typography component="span" className={classnames(styles.runningTextContent)}>
            ELFBAR WHOLESALE · VOZOL WHOLESALE · HQD WHOLESALE · SNUS WHOLESALE
          </Typography>
        </div>
        <Toolbar className={classnames(styles.toolbar)}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={2} container justifyContent="center">
              <Link to={`/`} style={{textDecoration: 'none'}}>
                <img src={PuffOptLogo} alt="Puff Opt Logo" height="40" />
              </Link>
            </Grid>
            <Grid item xs={1} container justifyContent="center" className={classnames(styles.userAvatar)}>
              <Avatar sx={{width: '32px', height: '32px'}}src={Cookies.get('avatar') ? Cookies.get('avatar') : userInfo.avatar} alt="User Avatar"/>
            </Grid>
            <Grid item xs={3} container justifyContent="center" className={classnames(styles.userInfoCol)}>
              <Grid container alignItems="left" className={classnames(styles.userInfo)}>
                <Typography xs={12} className={classnames(styles.userName)}>
                  @{Cookies.get('username') ? Cookies.get('username') : userInfo.username}
                </Typography>
                <Typography xs={12} className={classnames(styles.userTgName)}>
                  id: {Cookies.get('chatId') ? Cookies.get('chatId') : userInfo.chatId}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} container justifyContent="center" className={classnames(styles.priceListButton)}>
              <Link to={`/pricelist`} style={{textDecoration: 'none'}}>
                  <OrangeButton title={"pricelist"} />
              </Link>
            </Grid>
            <Grid item xs={2} container justifyContent="center" className={classnames(styles.shoppingCart)}>
              <Link to={`/cart`} style={{textDecoration: 'none', position: 'relative'}}>
                <img src={ShoppingCartIcon} alt="Puff Opt Logo" height="36" />
                {itemCount > 0 && (
                  <div className={styles.itemCount}>
                    {itemCount}
                  </div>
                )}
              </Link>
            </Grid>
            <Grid item xs={1} container justifyContent="center" className={classnames(styles.menuButton)}>
            <IconButton color="inherit" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
              {isDrawerOpen ? (
                <CloseIcon sx={{ transition: 'transform 0.3s ease-in-out', width: "36px", height: "36px" }} />
              ) : (
                <img src={MenuIcon} style={{ transition: 'transform 0.3s ease-in-out' }} alt="Puff Opt Logo" height="36" />
              )}
            </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        classes={{ paper: classnames(styles.fullscreenDrawer) }}
      >
        {menuList()}
      </Drawer>
    </>
  );
};

export default React.memo(connect(null, mapDispatchToProps)(Header));
